import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoMdClose, IoMdMenu } from "react-icons/io";

function MobileHeader({ className }) {
  const [menuHoverStyle, setMenuHoverStyle] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const menu = [
    {
      title: "home",
      link: "#",
    },
    {
      title: "about",
      link: "#",
    },
    {
      title: "academics",
      link: "#",
    },
    {
      title: " beyond academics",
      link: "#",
    },
    {
      title: " gallery",
      link: "#",
    },
    {
      title: " admissions",
      link: "#",
    },
    {
      title: " download",
      link: "#",
    },
    {
      title: " career",
      link: "#",
    },
    {
      title: " get in touch",
      link: "#",
    },
  ];

  const handleMenuItemhover = (index) => {
    if (index === index) {
      setMenuHoverStyle(index);
    }
  };

  return (
    <div className={`${className}`}>
      <div className="flex justify-center items-center w-full ">
        <div className="flex justify-between items-center w-[95%] lg:w-[70%] py-5 border-b ">
          {/* <div className="font-bold text-[#404040] text-xs">
            <p className="text-[#ED0C0A] mb-2">CALL US</p>
            <p className="flex items-center gap-2">
              {" "}
              <FaPhoneAlt />
              +91 9876543211
            </p>
            <p className="flex items-center gap-2">
              {" "}
              <MdEmail />
              school.mlmic@gmail.com
            </p>
          </div> */}
          <div className="">
            <img
              src={require("../../assets/school_logo.png")}
              alt=""
              className="w-[4rem]"
            />
          </div>{" "}
          <div className="me-5 ">
            {!showMenu && (
              <IoMdMenu
                className="text-3xl"
                onClick={() => setShowMenu(true)}
              />
            )}
            {showMenu && (
              <IoMdClose
                className="text-3xl"
                onClick={() => setShowMenu(false)}
              />
            )}
          </div>
          {/* <div className="">
            <CustomButton
              label={`ADMISSION ENQUIRY`}
              className={`bg-[#14A2B8] text-white hover:bg-[#ED0C0A] duration-300`}
            />
          </div> */}
        </div>
      </div>
      {showMenu && (
        <div
          className={`flex justify-center items-center w-full bg-[#293B61] text-white  animate-wiggle absolute  z-50`}
        >
          <div
            className={`flex flex-col w-[95%] lg:w-[70%]  uppercase font-bold text-sm cursor-pointer text-center`}
          >
            {menu?.map((items, index) => (
              <div
                key={index}
                className={` py-3  hover:text-[#ED0C0A]`}
                onMouseEnter={() => handleMenuItemhover(index)}
              >
                {items?.title}
              </div>
            ))}

            <CustomButton
              label={`ADMISSION ENQUIRY`}
              className={`bg-[#14A2B8] text-white hover:bg-[#ED0C0A] duration-300 border-none !p-5  font-extrabold  my-10`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileHeader;
