export const MOTILAL_STRINGS = {
  MLMEC_NAME_ENGLISH: "MOTILAL MEMORIAL EDUCATION CENTRE",
  MLMEC_NAME_HINDI: "मोतीलाल मेमोरियल एजुकेशन सेंटर",
  MLMIC_NAME_ENGLISH: "MOTILAL MEMORIAL INTER COLLEGE",
  MLMIC_NAME_HINDI: "मोतीलाल मेमोरियल इण्टर कॉलेज",
  SCHOOL_ADDRESS_ENGLISH: "Sector-J, Keshavpuram, Hasanpur Kanpur",
  SCHOOL_ADDRESS_HINDI: "सेक्टर-जे, केशवपुरम, हसनपुर कानपुर",
  SCHOOL_EMAIL_ID: "school.mlmic@gmail.com",
  SCHOOL_PHONE_NO: "0512 - 400 1137",
  NOTICE_FOR_HEADER:
    "Unlock a brighter future at Motilal Memorial Inter College – Admissions Open for 2025-26! 🌟 Enroll today and empower your child with excellence in education!",
  ABOUT_FOR_SCHOOL : "Motilal Memorial Inter College is a prestigious educational institution located in Sector-J Hasanpur Keshavpuram Kalyanpur, Kanpur, Uttar Pradesh.\n\n" +
"Established with a commitment to academic excellence and holistic development, the college provides quality education to students at the Intermediate level.\n\n" +
"The college is run by the Motilal Memorial Society, which was founded in 1982 to promote education and knowledge. The Society aims to promote learning through the establishment of schools, colleges, libraries, and research centers.\n\n" +
"At Motilal Memorial Inter College, students receive a well-rounded education that not only focuses on academic subjects but also encourages extracurricular activities, discipline, and values.\n\n" +
"The institute follows a structured curriculum and provides a nurturing environment for students to excel in their studies and build a strong foundation for their future.\n\n" +
"With a dedicated faculty, modern facilities, and a mission to inspire young minds, the college continues to be a leading educational institution in the region.\n\n" +
"For more information, you can visit the college or contact:\n" +
"📍 Address: Keshav Puram, Kalyanpur, Kanpur, Uttar Pradesh - 208017\n" +
"📞 Phone: (0512) 2510131"
};

export const STUDENT_REGISTRATION_FORM_CONSTANT = {
  STUDENT_CLASSES_ARRAY: [
    "PG",
    "LKG",
    "UKG",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
  ],
  STUDENT_SECTION_ARRAY: ["A", "B", "C", "D", "E", "F", "NA"],
  STUDENT_MEDIUM_ARRAY: ["HINDI", "ENGLISH", "OTHER"],
  STUDENT_RELIGION_ARRAY: [
    "HINDU",
    "MUSLIM",
    "SIKH",
    "CHRISTIAN",
    "BUDDHIST",
    "JAIN",
    "OTHER",
  ],
  STUDENT_CASTE_ARRAY: ["GENERAL", "OBC", "SC", "ST", "EWS", "OTHER"],
  MONTHS_ARRAY: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  RELETIONS: [
    "Father",
    "Mother",
    "Sister",
    "Brother",
    "Uncle",
    "Aunty",
    "Other",
  ],
  FATHER_OCCUPATION: [
    "Private Job",
    "Business",
    "Mazadoor",
    "Government Job",
    "Teacher",
    "Doctor",
    "Engineer",
    "Farming ",
    "Other",
  ],
  MOTHER_OCCUPATION: [
    "House Wife",
    "Private Job",
    "Business",
    "Mazadoor",
    "Government Job",
    "Teacher",
    "Doctor",
    "Engineer",
    "Other",
  ],
  UDICE_NUMBER_ARRAY: [
    {
      lable: "PG - 5th (09341501027)",
      number: "09341501027",
    },
    {
      lable: "6th - 8th (09341300319)",
      number: "09341300319",
    },
    {
      lable: "9th - 12th (09341500184)",
      number: "09341500184",
    },
    {
      lable: "Other",
      number: "",
    },
  ],
  COMMON_SUBJECTS: [
    "Hindi",
    "English",
    "Mathematics",
    "Home Science",
    "Science",
    "Social Science",
    "Drawing",
    "Physics",
    "Chemistry",
    "Biology",
    "Other",
  ],
};

// export const STUDENT_SECTION_ARRAY = ["A", "B", "C", "NA"];
// export const STUDENT_MEDIUM_ARRAY = ["HINDI", "ENGLISH", "OTHER"];
