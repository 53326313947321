export const Utils = {
  getDayName(dateString) {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day of the week as a number (0-6)
    const dayIndex = date.getDay();

    // Return the name of the day
    return daysOfWeek[dayIndex];
  },

  getMonthNameByMonthNumber(monthNumber) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Adjust monthNumber to be zero-based (0 for January, 11 for December)
    const index = monthNumber;

    // Validate if index is within range
    if (index >= 0 && index < months.length) {
      return months[index];
    } else {
      return "Invalid month number";
    }
  },

  extractDate(dateTimeString) {
    const dateObject = new Date(dateTimeString);
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Month is zero-based, so we add 1
    const day = dateObject.getDate();

    // Formatting the date components
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  },

  // // Example usage:
  // const dateTimeString = '1996-04-26T18:30:00.000Z';
  // console.log(extractDate(dateTimeString)); // Output: 1996-04-26

  formateDateForAttendanceRangeApiRequest: (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  formateDateForApiRequest: (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  formateDateForApiRequestNextDayDate: (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Get the date of the next day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  formatDate: (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  },
  formatDateForRequesBody: (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  generateYears: (count) => {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let i = 0; i < count; i++) {
      const year = currentYear - i;
      years.push(year);
    }

    return years;
  },

  generateAcademicYears: (count) => {
    const currentYear = new Date().getFullYear();
    const academicYears = [];

    for (let i = 0; i < count; i++) {
      const startYear = currentYear - i;
      const endYear = startYear + 1;
      const academicYear = `${startYear}-${endYear}`;
      academicYears.push(academicYear);
    }

    return academicYears;
  },
  generateAcademicYears1111111: (count, baseYear) => {
    if (count <= 0 || !Number.isInteger(count)) {
      throw new Error("Count must be a positive integer");
    }

    const today = new Date();
    const currentYear = baseYear ?? today.getFullYear();
    const isBeforeApril = today.getMonth() < 3; // January (0), February (1), March (2)

    const academicStartYear = isBeforeApril ? currentYear - 1 : currentYear;

    return Array.from({ length: count }, (_, i) => {
      const startYear = academicStartYear - i;
      return `${startYear}-${startYear + 1}`;
    });
  },
  previousAcademicYear: () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 1;
    const endYear = currentYear;
    const academicYear = `${startYear}-${endYear}`;
    return academicYear;
  },

  formatDateAndDayOfWeek: (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString(); // Adjust locale as needed
    const dayOfWeek = date.getDay();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayString = daysOfWeek[dayOfWeek];

    return { formattedDate, dayString };
  },

  formatDateBeforeInString: (dateString, period) => {
    const date = new Date(dateString);

    switch (period) {
      case "week":
        date.setDate(date.getDate() - 7);
        break;
      case "2week":
        date.setDate(date.getDate() - 15);
        break;
      case "month":
        date.setMonth(date.getMonth() - 1);
        break;
      case "year":
        date.setFullYear(date.getFullYear() - 1);
        break;
      case "today":
        // No need to modify date, just return today's date
        break;
      default:
        throw new Error(
          "Invalid period. Supported periods are 'week', '2week', 'month', 'year', and 'today'."
        );
    }

    return date.toString();
  },

  formatDateBefore: (dateString, period) => {
    const date = new Date(dateString);

    switch (period) {
      case "week":
        date.setDate(date.getDate() - 7);
        break;
      case "2week":
        date.setDate(date.getDate() - 15);
        break;
      case "month":
        date.setMonth(date.getMonth() - 1);
        break;
      case "year":
        date.setFullYear(date.getFullYear() - 1);
        break;
      case "today":
        // Set both startDate and endDate to today's date
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const day = today.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      default:
        throw new Error(
          "Invalid period. Supported periods are 'week', '2week', 'month', 'year', and 'today'."
        );
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  getTodayDate: () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },

  getMonthName: (monthNumber) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May + June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return "Invalid month number";
    }
  },
};

export const months = [
  // "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
