import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { MdMenu, MdClose } from "react-icons/md";

const images = [
  require("../../assets/SliderImage/slide6.jpg"),
  require("../../assets/SliderImage/slide2.jpg"),
  require("../../assets/SliderImage/slide4.jpg"),
];

function HeaderWithSlider({ className }) {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const menu = [
    { title: "About", link: "#" },
    { title: "Admissions", link: "#" },
    { title: "Academics", link: "#" },
    { title: "Research", link: "#" },
    {
      title: "Pages",
      link: "#",
      dropdown: [
        { title: "Page 1", link: "#" },
        { title: "Page 2", link: "#" },
      ],
    },
    { title: "Contact", link: "#" },
  ];

  // Automatic Image Slider
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      className={`relative w-full h-[600px] md:h-[100vh] overflow-hidden ${className}`}
    >
      {/* Image Slider */}
      <div className="absolute inset-0 w-full h-full">
        {images.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Slide ${index + 1}`}
            className={`absolute w-full h-full object-cover transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          />
        ))}
      </div>

      {/* Header Content */}
      <div className="absolute inset-0 bg-[rgb(17,48,77,0.3)] flex flex-col justify-between">
        {/* Navbar */}
        <Fade triggerOnce>
          <div className="w-full bg-transparent text-white ">
            <div className="flex justify-center items-center  py-2">
              {/* Hamburger Menu (Mobile) */}
              <div
                className="md:hidden text-2xl cursor-pointer"
                onClick={toggleMenu}
              >
                {isMenuOpen ? <MdClose /> : <MdMenu />}
              </div>

              {/* Navigation */}
              <nav
                className={`md:flex items-center justify-center gap-8  ${
                  isMenuOpen ? "block" : "hidden"
                } md:block`}
              >
                {/* Left Side Menu */}
                <ul className="flex gap-6 ">
                  {menu.slice(0, 3).map((item, index) => (
                    <li
                      key={index}
                      className="relative group cursor-pointer hover:text-yellow-400"
                      onClick={() => navigate(item.link)}
                    >
                      <span className="relative after:content-[''] after:block after:h-[2px] after:w-0 after:bg-yellow-400 after:transition-all after:duration-300 group-hover:after:w-full">
                        {item.title}
                      </span>
                    </li>
                  ))}
                </ul>

                {/* Centered Logo */}
                <div className="mx-8">
                  <img
                    src={require("../../assets/school_logo.png")}
                    alt="School Logo"
                    className="w-24 h-24 object-contain cursor-pointer"
                    onClick={() => navigate("/")}
                  />
                </div>

                {/* Right Side Menu */}
                <ul className="flex gap-6 text-sm">
                  {menu.slice(3).map((item, index) => (
                    <li
                      key={index}
                      className="relative group cursor-pointer hover:text-yellow-400"
                      onClick={() => navigate(item.link)}
                    >
                      {item.dropdown ? (
                        <>
                          <span onClick={toggleDropdown}>{item.title} ▾</span>
                          {dropdownOpen && (
                            <div className="absolute top-full left-0 bg-white text-black shadow-md rounded-md mt-2 w-40">
                              {item.dropdown.map((subItem, subIndex) => (
                                <div
                                  key={subIndex}
                                  className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                  onClick={() => navigate(subItem.link)}
                                >
                                  {subItem.title}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <span className="relative after:content-[''] after:block after:h-[2px] after:w-0 after:bg-yellow-400 after:transition-all after:duration-300 group-hover:after:w-full">
                          {item.title}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default HeaderWithSlider;
