import React, { useState } from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { MOTILAL_STRINGS } from "../../StringConstant/MotiLalAllConstant";
import CustomBtn from "../../Shared/CustomButton/CustomBtn";
import { Fade } from "react-awesome-reveal";

function Header({ className }) {
  const navigate = useNavigate();
  const [menuHoverStyle, setMenuHoverStyle] = useState(-1);

  const menu = [
    { title: "home", link: "/" },
    { title: "about", link: "#" },
    { title: "academics", link: "#" },
    { title: "beyond academics", link: "#" },
    { title: "gallery", link: "/gallery" },
    { title: "download", link: "#" },
  ];

  const handleMenuItemHover = (index) => {
    setMenuHoverStyle(index);
  };

  return (
    <div className={`${className}`}>
      {/* Notice Bar */}
      <Fade triggerOnce>
        <div className="w-full bg-[#012B53] flex items-center">
          <CustomButton
            label="Notice"
            className="bg-[#012B53] hover:bg-[#293B61] border-none !text-xs text-white rounded-none !py-1"
          />
          <marquee behavior="" direction="" className="text-white text-xs me-5">
            {MOTILAL_STRINGS?.NOTICE_FOR_HEADER}
          </marquee>
        </div>
      </Fade>

      {/* Contact Info and Logo */}
      <Fade triggerOnce>
        <div className="flex justify-center items-center w-full">
          <div className="flex justify-between items-center lg:w-[80%] py-1">
            <div className="text-[#404040] text-sm">
              <p className="text-[#ED0C0A] mb-2 font-medium">CALL US :</p>
              <p className="flex items-center gap-2">
                <MdLocalPhone className="text-lg" />
                {MOTILAL_STRINGS?.SCHOOL_PHONE_NO}
              </p>
              <p className="flex items-center gap-2">
                <MdEmail className="text-lg" />
                {MOTILAL_STRINGS?.SCHOOL_EMAIL_ID}
              </p>
            </div>

            <div className="cursor-pointer" onClick={() => navigate("/")}>
              <img
                src={require("../../assets/school_logo.png")}
                alt="School Logo"
                className="w-[6rem]"
              />
            </div>

            <div>
              <CustomBtn label="ADMISSION ENQUIRY" className="" />
            </div>
          </div>
        </div>
      </Fade>

      {/* Menu Bar */}
      <Fade triggerOnce>
        <div className="flex justify-center items-center w-full bg-[#293B61] text-white">
          <div className="flex justify-center items-center lg:w-[90%] xl:w-[80%] gap-10 uppercase text-sm cursor-pointer">
            {menu.map((items, index) => (
              <div
                key={index}
                className={`py-5 transition-transform duration-300 ${
                  menuHoverStyle === index ? "scale-110 text-yellow-400" : ""
                }`}
                onMouseEnter={() => handleMenuItemHover(index)}
                onMouseLeave={() => handleMenuItemHover(-1)}
                onClick={() => navigate(`${items.link}`)}
              >
                {items.title}
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default Header;
