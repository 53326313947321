import React, { useEffect } from "react";

function LoaderWithLogo() {
  useEffect(() => {
    // Disable scrolling
    document.body.classList.add("overflow-hidden");

    return () => {
      // Re-enable scrolling when component unmounts
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className="w-full h-screen fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-200/80 z-50">
      {/* Spinning Outer Circle */}
      <div className="w-[10rem] h-[10rem] border-t-4  border-gray-600 rounded-full flex justify-center items-center  animate-spin relative">
        {/* Inner Logo (Explicitly Not Spinning) */}
      </div>
      <img
        src={require("../assets/school_logo.png")}
        alt="School Logo"
        className="w-[7rem] animate-showHide absolute" // Ensures logo doesn't spin
      />
    </div>
  );
}

export default LoaderWithLogo;
