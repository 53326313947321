import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import MobileHeader from "../Header/MobileHeader";
import Cards from "../Cards/Cards";
import PrincipleBox from "../PrincipleBox/PrincipleBox";
import SchoolEvents from "../SchoolEvents/SchoolEvents";
import Enquery from "../Enquery/Enquery";
import Footer from "../Footer/Footer";
import AdmissionFormPopup from "../AdmissionFormPopup/AdmissionFormPopup";
import CustomSlider from "../Slider/Slider";
import LoaderWithLogo from "../../AllLoaders/LoaderWithLogo";

import "./HomePage.css";
import AboutSchool from "../AboutSchool/AboutSchool";
import CountingCards from "../CountingCards/CountingCards";
import HeaderWithSlider from "../Header/HeaderWithSlider";

function HomePage() {
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   // Set a timeout to hide the loader after 3 seconds
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 3000);

  //   // Cleanup the timer when the component unmounts
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      {isLoading ? (
        // Show the loader while isLoading is true
        <LoaderWithLogo />
      ) : (
        <>
          <Header className="hidden  lg:block" />

          <MobileHeader className="lg:hidden" />

          {/* <HeaderWithSlider /> */}

          <CustomSlider />

          <AboutSchool />

          <CountingCards />

          {/* <Cards /> */}

          {/* <PrincipleBox /> */}

          <SchoolEvents />

          <Enquery />

          <Footer />
        </>
      )}
    </>
  );
}

export default HomePage;
