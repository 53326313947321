import React, { useEffect, useState } from "react";
import img from "../../assets/SliderImage/slide2.jpg";

const data = [
  { title: "DANCE CLASSES", count: 158 },
  { title: "BEST INSTRUCTOR", count: 49 },
  { title: "TOTAL BRUNCH", count: 17 },
  { title: "HAPPY CUSTOMER", count: 567 },
];

function CountingCards() {
  const [counts, setCounts] = useState(data.map(() => 0));

  useEffect(() => {
    const interval = setInterval(() => {
      setCounts((prevCounts) =>
        prevCounts.map((val, index) =>
          val < data[index].count ? val + 1 : data[index].count
        )
      );
    }, 20); // Adjust speed (lower value = faster count)

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="bg-cover bg-no-repeat bg-fixed"
      style={{
        backgroundImage: `url(${img})`,
      }}
    >
      <div className="w-full py-20 bg-gray-300/70 flex items-center justify-center">
        <div className="w-[95%] tablet:w-[80%] lg:w-[70%] flex flex-col md:flex-row justify-between">
          {data.map((items, index) => (
            <div key={index} className="w-[23%]">
              <div className="flex justify-center items-center py-8 bg-primaryBgColor text-primaryTextColor rounded-t-xl font-semibold">
                {items.title}
              </div>
              <div className="flex justify-center items-center py-10 bg-white rounded-b-full text-secondaryTextColor text-2xl font-bold">
                {counts[index]}+
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CountingCards;
