import React from "react";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { MOTILAL_STRINGS } from "../../StringConstant/MotiLalAllConstant";
import CustomBtn from "../../Shared/CustomButton/CustomBtn";

function AboutSchool() {
  return (
    <div className="w-full flex  justify-center my-10">
      <div className="w-[95%] tablet:w-[80%] lg:w-[70%] flex flex-col lg:flex-row justify-between items-start">
        <div className="w-full lg:w-[45%] p-5 lg:px-0 ">
          <p className="text-xl lg:text-3xl font-extrabold capitalize text-secondaryTextColor">
            about The School
          </p>
          {/* <p className="font-bold text-green-600">THE GREEN SCHOOL</p> */}
          <div className="flex flex-col gap-1 text-sm py-3 text-[#586584]">
            <p className=" ">
              Motilal Memorial Inter College is a prestigious educational
              institution located in Sector-J Hasanpur Keshavpuram Kalyanpur,
              Kanpur, Uttar Pradesh.
            </p>
            <p className=" ">
              Established with a commitment to academic excellence and holistic
              development, the college provides quality education to students at
              the Intermediate level.
            </p>
            <p className=" ">
              The college is run by the Motilal Memorial Society, which was
              founded in 1982 to promote education and knowledge. The Society
              aims to promote learning through the establishment of schools,
              colleges, libraries, and research centers
            </p>
            <p className=" ">
              At Motilal Memorial Inter College, students receive a well-rounded
              education that not only focuses on academic subjects but also
              encourages extracurricular activities, discipline, and values.
            </p>
            {/* <p className=" ">
              The institute follows a structured curriculum and provides a
              nurturing environment for students to excel in their studies and
              build a strong foundation for their future.
            </p>
            <p className=" ">
              With a dedicated faculty, modern facilities, and a mission to
              inspire young minds, the college continues to be a leading
              educational institution in the region.
            </p> */}
            {/* <p className="">
              For more information, you can visit the college or contact:
            </p>
            <p className="">
              📍 Address: Keshav Puram, Kalyanpur, Kanpur, Uttar Pradesh -
              208017
            </p>
            <p className="">📞 Phone: (0512) 2510131</p> */}
          </div>

          {/* <p>
           {MOTILAL_STRINGS?.ABOUT_FOR_SCHOOL}
          </p> */}

          {/* <CustomButton
            label={`KNOW MORE`}
            className={`hover:bg-[#14A2B8] text-white bg-[#ED0C0A] duration-300 !mx-0 !p-5 !font-bold`}
          /> */}
          <CustomBtn label="KNOW MORE" className="" />
        </div>
        <div className="w-full lg:w-[50%] mt-5 lg:mt-0">
          <img
            src={require("../../assets/School_photos/galery7.jpg")}
            alt=""
            className="rounded-xl"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutSchool;
